import React, { useEffect, useState } from 'react';
import QRious from 'qrious'
const defaultLink = 'https://app.appsflyer.com/id1561940699?pid=googleadwords_int&af_siteid={placement}&af_c_id={campaignid}&af_adset={adgroupid}&af_adset_id={adgroupid}&af_ad={creative}&af_sub1={gclid}&af_click_lookback=7d&network={network}&af_keywords={keyword}&url={lpurl}&af_sub2={gbraid}&af_sub3={wbraid}&af_r={lpurl}'
function App() {
  const [link, setLink] = useState(defaultLink)
  const [parameters, setParameters] = useState({})
  const [afLink, setAfLink] = useState('')
  useEffect(() => {
    const query = parseUrlQuery(window.location.href)
    setParameters(query);
    window.onerror = function () {
      console.log(arguments)
      alert('哎唷，发生了不得了的错误，快联系开发者吧！')
      return true;
    }
  }, []);

  useEffect(() => {
    const query = parseUrlQuery(link)
    setParameters(query);
  }, [link]);

  useEffect(() => {
    let afLink = link.split('?')[0]
    let query = ''
    Object.keys(parameters).forEach(key => {
      const value = parameters[key];
      if (value.startsWith('{')) {
        return
      }
      query += `${key}=${value}&`
    })
    if (query.endsWith('&')) {
      query = query.slice(0, -1)
    }
    afLink += '?' + query
    setAfLink(afLink)
    generateQRCode(afLink, 'af-qr')
    generateQRCode(web2iosLink(afLink), 'web2ios-qr')
  }, [parameters]);

  return (
    <div className="w-full bg-gray-100 p-6">
      <h1 className="text-2xl font-bold">输入AF链接:</h1>
      <p className="text-base text-gray-700 mt-2">示例：<br />{defaultLink}</p>
      <p className="text-base text-gray-500 mt-2">参数以 {`{}`}的形式拼接可以自动识别留空</p>
      <p className="text-base text-yellow-500 mt-2">页面下方填参数</p>
      <input className="w-6/12 mt-4 p-2 border border-gray-300 rounded-md"
        placeholder="粘贴在此处" value={link}
        onChange={(e) => setLink(e.target.value)} />
      <h1 className="text-xl font-bold mt-4">AF 链接输出:</h1>
      <a className="text-base text-gray-500 mt-2 hover:text-blue-400" href={afLink}>{afLink}</a>
      <canvas id="af-qr" className='mt-4'></canvas>
      <h1 className="text-xl font-bold mt-4">web2ios 链接输出:</h1>
      <a className="w-full text-base break-all text-gray-500 mt-2 hover:text-blue-400" href={web2iosLink(afLink)}>{web2iosLink(afLink)}</a>
      <canvas id="web2ios-qr" className='mt-4'></canvas>
      <h1 className="text-xl font-bold mt-4">填写参数:</h1>
      <p className="text-base text-gray-500 mt-2">没有填写的参数会被忽略</p>
      <div className='flex flex-wrap'>
        {
          Object.keys(parameters).map((key) => {
            return <ParameterInput valueKey={key} key={key}
              value={parameters[key]}
              onChange={(value) => {
                setParameters({
                  ...parameters,
                  [key]: value
                })
              }}
            />
          })
        }
      </div>
    </div>
  );
}

export default App;

function parseUrlQuery(url) {
  const query = {}
  const queryStr = url.split('?')[1]
  if (queryStr) {
    queryStr.split('&').forEach(item => {
      const [key, value] = item.split('=')
      if (key) {
        query[key] = value
      }
    })
  }
  return query
}

function ParameterInput({ valueKey, value, onChange }) {
  const [inputValue, setInputValue] = useState(value ? (value.startsWith('{') ? '' : value) : '')
  return (
    <div className="mt-4 text-gray-400" key={valueKey}>
      <span className="font-bold grid-cols-5">{valueKey}</span>
      <input onChange={(e) => {
        const value = e.target.value
        setInputValue(value)
        onChange(value)
      }}
        placeholder={value}
        value={inputValue} className="grid-cols-5 h-8 mt-4 ml-2 p-2 border border-gray-300 rounded-md text-gray-700" />
    </div>
  )
}

function generateQRCode(text, id) {
  new QRious({
    element: document.getElementById(id),
    size: 200,
    value: text
  })
}

function web2iosLink(link) {
  return `https://collart.app/landing/?forward=${encodeURIComponent(link)}`
}